import { useEffect, useState } from 'react';
import { useAtomValue } from 'jotai';
import { userViewTypeAtom } from '@middleware-old/atom/auth.atom';

declare global {
  interface Window {
    dataLayer: unknown[];
    HubSpotConversations: any;
  }
}

export default function ChatHubSpot() {
  const userViewType = useAtomValue(userViewTypeAtom);
  const [previusViewType, setPreviusViewType] = useState<any>();

  useEffect(() => {
    (() => {
      if (window.HubSpotConversations && userViewType && previusViewType !== userViewType) {
        console.log('Resetting HubSpotConversations');
        setPreviusViewType(userViewType);
        setTimeout(() => {
          window.HubSpotConversations.resetAndReloadWidget();
        }, 1000);
      }
    })();
  }, [previusViewType, userViewType]);

  return null;
}
