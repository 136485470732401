import BVUtils from '@bv/utils';
import { TUserRoles } from '../../../types/user';

import * as singleSpa from 'single-spa';

const MF_PORTS: { [key: string]: string } = {
  //global 9000
  '@bv/root-config': '9000',
  '@bv/styleguide': '9001',
  '@bv/utils': '9002',
  '@bv/global-nav-menu': '9003',
  '@bv/global-not-found': '9004',
  '@bv/global-nav-user': '9005',

  //public 3000
  '@bv/public-auth': '3000',
  '@bv/public-pre-invite': '3001',
  '@bv/public-registration': '3002',
  '@bv/public-review': '3003',

  //employee 4000
  '@bv/employee-dashboard': '4000',
  '@bv/employee-discounts': '4001',
  '@bv/employee-documents': '4002',
  '@bv/employee-near-visual-acuity-test': '4003',
  '@bv/employee-away-visual-acuity-test': '4005',

  // admin 5000
  '@bv/admin-companies': '5001',
  '@bv/admin-users': '5002',

  // doctor 6000
  '@bv/doctor-exams': '6001',

  // optics 7000
  '@bv/optics-dashboard': '7000',
  '@bv/optics-campaigns': '7001',
  '@bv/optics-prescriptions': '7002',

  //old
  '@bv/components-old': '8000',
  '@bv/utils-old': '8001',
  '@bv/middleware-old': '8002',
  '@bv/login-old': '8003',
  '@bv/test-visual-acuity-ai-old': '8006',
  '@bv/invited-old': '8007',
  '@bv/discounts-old': '8008',
  '@bv/invites-old': '8009',
  '@bv/plans-old': '8010',
  '@bv/profile-old': '8011',
  '@bv/dashboard-admin-old': '8012',
  '@bv/sales-old': '8013',
  '@bv/telemedicine-old': '8014',
  '@bv/test-ishihara-old': '8015',
  '@bv/test-visual-acuity-old': '8016',
  '@bv/triagem-old': '8017',
  '@bv/users-old': '8018',
  '@bv/optics-exchange-prescription-old': '8019',
  '@bv/company-management-old': '8021',
  '@bv/products-old': '8022',
};

type TApplication = {
  name: string;
  url: string;
  activeWhen: string[] | null;
  isAlwaysActive: boolean;
};

export function routePermissionHandler() {
  return new Promise<void>(async (resolve, reject) => {
    const decodedToken = BVUtils.token.getDecodedToken();
    const userViewType: TUserRoles | null = JSON.parse(localStorage.getItem('userViewType')) ?? null;
    const unitInSession = BVUtils.session.getUnitInSessionUtil();

    // Aqui verificamos se o usuário é um paciente sem vinculo empregaticio ou se ele é um usuário com vinculo empregaticio
    const statement =
      userViewType === 'patient'
        ? await import(`../mock/${userViewType}.statements`)
        : await import(`../mock/${unitInSession.enabledContractsDetails.type || 'benefit'}.${userViewType}.statements`);

    console.log({ statement });

    let applications = [...statement.default];
    let planDependentApplications = [];
    if (decodedToken?.modules && userViewType !== 'patient') {
      const planDependentStatement = await import(`../mock/plan.dependent.statements`);

      decodedToken.modules.forEach((module) => {
        const planDependentModule = planDependentStatement.default[module];
        // console.log({ planDependentModule });
        if (planDependentModule[0]) planDependentApplications = [...planDependentApplications, ...planDependentModule];
      });
    }

    applications = [...applications, ...planDependentApplications];

    console.log({ applications });

    //Processamento para montagem das urls dos microservicos, de acordo com o ambiente.
    const processedUrlApplications = applications.map((element: TApplication) => {
      const mfName: string = element.name.replace('@bv/', '');
      return {
        ...element,
        url:
          process.env.NODE_ENV === 'development'
            ? `${process.env.MF_URL}:${MF_PORTS[element.name]}/bv-${mfName}.js`
            : `${process.env.MF_URL}/mf/${mfName}/bv-${mfName}.js`,
      };
    });

    const applicationsToMount = [...processedUrlApplications];

    applicationsToMount.forEach((element: TApplication) => {
      singleSpa.registerApplication({
        name: element.name,
        app: () => System.import<singleSpa.LifeCycles>(element.url),
        activeWhen: element.isAlwaysActive ? () => true : element.activeWhen,
      });
    });

    const mountedApps = singleSpa.getAppNames();
    console.log({ mountedApps });
    resolve();
  });
}
