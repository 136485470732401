import { toast } from "sonner";

export type TNotification = {
  message: string;
  type: "success" | "error" | "info" | "warning";
  title?: string;
};

export const toastHandler = (notification: TNotification) => {
  const { message, type, title } = notification;
  if (title) {
    toast[type](title, {
      description: message,
    });
  } else {
    toast[type](message);
  }
};
