import { TUser, TUserRoles } from '../../../types/user';
import { atomWithStorage } from 'jotai/utils';

export const tokenAtom = atomWithStorage<string | null>(
  'token',
  JSON.parse(localStorage.getItem('token')) || JSON.parse(sessionStorage.getItem('token')) || null,
);

export const userAtom = atomWithStorage<TUser | null>('user', (JSON.parse(localStorage.getItem('user')) as TUser) || null);

export const userViewTypeAtom = atomWithStorage<string | null>(
  'userViewType',
  (JSON.parse(localStorage.getItem('userViewType')) as TUserRoles) || null,
);
