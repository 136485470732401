import { TOptions } from '../../../../types/select';
import { TUserRoles } from '../../../../types/user';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import BVUtils from '@bv/utils';
import BVUtilsOld from '@bv/utils-old';
import BVComponentsOld from '@bv/components-old';
import * as singleSpa from 'single-spa';
import { useAtom, useAtomValue } from 'jotai';
import { userAtom, userViewTypeAtom } from '../../atom/auth.atom';
import { ArrowRight } from 'lucide-react';
import { logoBVAsset } from '@middleware-old/assets';

const {
  hooks: { useAuth },
} = BVUtilsOld;

export function SelectTypeUser() {
  const { logout } = useAuth();
  // const { user, setRealodUserViewType, setShowSelectUserType } =
  //   useUserInfoContext();
  const [userViewType, setUserViewType] = useAtom(userViewTypeAtom);
  const decodedToken = BVUtils.token.getDecodedToken();
  const [option, setOption] = useState<TOptions>(undefined);
  const [showError, setshowError] = useState(false);

  const options: TOptions[] | [] = useMemo(() => {
    if (decodedToken) {
      const roles = decodedToken?.roles.map((role: TUserRoles) => {
        let label: TUserRoles | string = role;
        switch (role) {
          case 'admin':
            label = 'Administrador';
            break;
          case 'doctor':
            label = 'Médico ocupacional';
            break;
          case 'employee':
            label = 'Colaborador';
            break;
          default:
            label = role;
        }

        return { label: label, value: role };
      });

      return roles;
    }

    return [];
  }, [decodedToken]);

  function doLogout() {
    logout();
    window.location.reload();
  }

  function setViewType() {
    if (!validateForm()) {
      setshowError(true);
      return;
    }
    setshowError(false);

    const typeView: TUserRoles = option.value as TUserRoles;
    setUserViewType(typeView);
    window.location.reload();
  }

  function validateForm(): boolean {
    if (!option) {
      return false;
    }

    return true;
  }

  useEffect(() => {
    if (!!option == true) {
      setshowError(false);
    }
  }, [option]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Selecione o tipo de usuário | BV </title>
      </Helmet>
      <div className="bg-interface fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center p-2">
        <div className="rounded-2 flex-col items-center gap-8 bg-white p-8">
          <img className="mx-auto mb-4 block" src={logoBVAsset} alt="logo-bv" width={178} height={65} />
          <div className="flex flex-col gap-2">
            <strong className="text-primary-dark text-xl">Olá {decodedToken?.name}, tudo bem?</strong>
            <p>Com qual usuário você deseja navegar no seu benefício.</p>
          </div>

          <div className="relative my-4 w-full">
            <BVComponentsOld.Select
              label="Tipo de usuário"
              classNameContainer="w-100"
              placeholder="Selecionar"
              //   getOptionLabel={(company) => company?.name}
              //   getOptionValue={(company) => company?.id}
              onChange={(item) => {
                setOption(item as unknown as TOptions);
              }}
              options={options}
            />

            <div className="absolute">
              <span hidden={!showError} className="text-danger">
                Selecione um tipo de usuário
              </span>
            </div>
          </div>

          <div className="w-100 flex-sm-row flex flex-col-reverse justify-between gap-2">
            <button className="btn btn-link" onClick={doLogout}>
              cancelar
            </button>
            <button className="btn btn-primary flex items-center justify-center gap-3" onClick={setViewType}>
              Continuar
              <ArrowRight size={16} />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
