enum ECompanyType {
  BENEFIT = 'benefit',
  BUSINESS_UNIT = 'businessUnit',
  OPTICS = 'optics',
}

enum ERole {
  SUPER_ADMIN = 'superAdmin',
  ADMIN = 'admin',
  EMPLOYEE = 'employee',
  GUEST = 'guest',
  PACIENT = 'pacient',
  DOCTOR = 'doctor',
}

export const PUBLIC_ROUTES = [
  '/login',
  '/login/esqueci-senha',
  '/login/redefinir-senha',
  '/pre-convite',
  '/pre-convite/identificador',
  '/pre-convite/validar-otp',
  '/pre-convite/sucesso',
  '/convidado',
  '/otica/trocar-receita',
  '/otica/trocar-receita/tour',
  '/otica/trocar-receita/antes-de-comecar',
  '/otica/trocar-receita/login',
  '/otica/trocar-receita/validar-otp',
  '/otica/trocar-receita/cadastro',
  '/otica/trocar-receita/erro/nao-pode-prosseguir',
  '/otica/trocar-receita/aviso/aguardando-avaliacao',
  '/otica/trocar-receita/minha-receita',
  '/avaliar',
  '/avaliar/sucesso',
];

export const routes = {
  ADMIN_DASHBOARD: {
    name: 'Início',
    url: '/admin',
    public: false,
    companyTypes: ['benefit', 'occupational', 'businessUnit'],
    roles: ['admin'],
    requiredModules: null,
  },
  CONVITES: {
    name: 'Convites',
    url: '/convites',
    public: false,
    companyTypes: ['benefit', 'occupational', 'businessUnit'],
    roles: ['admin'],
    requiredModules: null,
  },
  DASHBOARD: {
    name: 'Dashboard',
    url: '/dashboard',
    public: false,
    companyTypes: ['benefit', 'occupational', 'businessUnit'],
    roles: ['employee'],
    requiredModules: null,
  },
  DESCONTOS: {
    name: 'Descontos',
    url: '/descontos',
    public: true,
    companyTypes: ['benefit', 'occupational', 'businessUnit'],
    roles: ['employee', 'admin'],
    requiredModules: null,
  },
  DESCONTOS_ZIPCODE: {
    name: 'Descontos',
    url: '/descontos/:zipcode',
    public: true,
    companyTypes: ['benefit', 'occupational', 'businessUnit'],
    roles: ['employee', 'admin'],
    requiredModules: null,
  },
  EXAMES: {
    name: 'Exames',
    url: '/exames',
    public: false,
    companyTypes: ['benefit', 'occupational', 'businessUnit'],
    roles: ['doctor'],
    requiredModules: null,
  },
  EMPRESAS: {
    name: 'Empresas',
    url: '/empresas',
    public: false,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  EMPRESAS_CADASTRAR: {
    name: 'Empresas',
    url: '/empresas/adicionar-empresa',
    public: false,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  EMPRESAS_VISUALIZAR_MATRIZ: {
    name: 'Empresas',
    url: '/empresas/matriz/:id',
    public: false,
    companyTypes: null,
    roles: null,
    requiredModules: null,
    goToUrl: (companyId: string) => `/empresas/matriz/${companyId}`,
  },
  EMPRESAS_CADASTRAR_UNIDADE_DE_NEGOCIO: {
    name: 'Empresas',
    url: '/empresas/unidade-de-negocio/adicionar',
    public: false,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  GUEST: {
    name: 'Convidado',
    url: '/convidado',
    public: true,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  LOGIN: {
    name: 'Login',
    url: '/login',
    public: true,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  OPTICS_EXCHANGE_PRESCTIPTION: {
    name: 'Troca de receita',
    url: '/otica/trocar-receita',
    public: true,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  OPTICS_EXCHANGE_PRESCTIPTION_TOUR: {
    name: 'Troca de receita',
    url: '/otica/trocar-receita/tour',
    public: true,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  OPTICS_EXCHANGE_PRESCTIPTION_ANTES_DE_COMECAR: {
    name: 'Troca de receita',
    url: '/otica/trocar-receita/antes-de-comecar',
    public: true,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  OPTICS_EXCHANGE_PRESCTIPTION_LOGIN: {
    name: 'Troca de receita',
    url: '/otica/trocar-receita/login',
    public: true,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  OPTICS_EXCHANGE_PRESCTIPTION_VALIDAR_OTP: {
    name: 'Troca de receita',
    url: '/otica/trocar-receita/validar-otp',
    public: true,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  OPTICS_EXCHANGE_PRESCTIPTION_CADASTRO: {
    name: 'Troca de receita',
    url: '/otica/trocar-receita/cadastro',
    public: true,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  OPTICS_EXCHANGE_PRESCTIPTION_ERRO_NAO_PODE_PROSSEGUIR: {
    name: 'Troca de receita',
    url: '/otica/trocar-receita/erro/nao-pode-prosseguir',
    public: true,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  OPTICS_EXCHANGE_PRESCTIPTION_UPLOAD_PRESCRICAO: {
    name: 'Troca de receita',
    url: '/otica/trocar-receita/upload-prescricao',
    public: true,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  OPTICS_EXCHANGE_PRESCTIPTION_UPLOAD_PRESCRICAO_AVISO_SEM_PRESCRICAO: {
    name: 'Troca de receita',
    url: '/otica/trocar-receita/upload-prescricao/aviso/sem-prescricao',
    public: true,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  OPTICS_EXCHANGE_PRESCTIPTION_AVISO_AGUARDANDO_AVALIACAO: {
    name: 'Troca de receita',
    url: '/otica/trocar-receita/aviso/aguardando-avaliacao',
    public: true,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  OPTICS_EXCHANGE_PRESCTIPTION_ERRO_IR_AO_MEDICO: {
    name: 'Troca de receita',
    url: '/otica/trocar-receita/erro/ir-ao-medico',
    public: true,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  OPTICS_EXCHANGE_PRESCTIPTION_SUCESSO: {
    name: 'Troca de receita',
    url: '/otica/trocar-receita/sucesso',
    public: true,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  PAGINA_NAO_ENCONTRADA: {
    name: 'Página não encontrada',
    url: '/pagina-nao-encontrada',
    public: true,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  PERFIL: {
    name: 'Meus dados',
    url: '/perfil',
    public: true,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  PERFIL_EDIT_PERSONAL_DATA: {
    name: 'Meus dados',
    url: '/perfil/edit-personal-data',
    public: true,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  PERFIL_EDIT_USER_ADDRESS: {
    name: 'Meus dados',
    url: '/perfil/edit-user-address',
    public: true,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  PERFIL_REDEFINE_PASSWORD: {
    name: 'Meus dados',
    url: '/perfil/redefine-password',
    public: true,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  PLANOS: {
    name: 'Planos',
    url: '/planos',
    public: false,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  PLANOS_CADASTRAR: {
    name: 'Novo plano',
    url: '/planos/criar-plano',
    public: false,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  PLANOS_EDITAR: {
    name: 'Editar plano',
    url: '/planos/editar-plano',
    public: false,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  PRODUTOS: {
    name: 'Produtos',
    url: '/produtos',
    public: false,
    companyTypes: ['optics'],
    roles: ['employee', 'admin'],
    requiredModules: null,
  },
  PRODUTOS_CADASTRAR: {
    name: 'Novo produto',
    url: '/produtos/novo-produto',
    public: false,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  PRODUTOS_EDITAR: {
    name: 'Editar produto',
    url: '/produtos/editar-produto',
    public: false,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  REDEFINIR_SENHA: {
    name: 'Redefinir senha',
    url: '/redefinir-senha',
    public: true,
    companyTypes: null,
    roles: null,
    requiredModules: null,
  },
  TELEMEDICINA: {
    name: 'Telemedicina',
    url: '/telemedicina',
    public: true,
    companyTypes: ['benefit', 'occupational', 'businessUnit'],
    roles: ['employee', 'admin'],
    requiredModules: null,
  },
  TESTE_ACUIDADE_VISUAL: {
    name: 'Teste de acuidade visual',
    url: '/acuidade-visual',
    public: false,
    companyTypes: ['benefit', 'occupational', 'businessUnit'],
    roles: ['employee', 'admin'],
    requiredModules: ['2'],
  },
  TESTE_ACUIDADE_VISUAL_AI: {
    name: 'Teste de acuidade visual',
    url: '/acuidade-visual-longe',
    public: false,
    companyTypes: ['benefit', 'occupational', 'businessUnit'],
    roles: ['employee', 'admin'],
    requiredModules: ['2'],
  },
  TESTE_ISHIHARA: {
    name: 'Teste de Ishihara',
    url: '/ishihara',
    public: false,
    companyTypes: ['benefit', 'occupational', 'businessUnit'],
    roles: ['employee', 'admin'],
    requiredModules: ['3'],
  },
  TRIAGEM: {
    name: 'Triagem',
    url: '/triagem',
    public: false,
    companyTypes: ['benefit', 'occupational', 'businessUnit'],
    roles: ['employee'],
    requiredModules: ['1'],
  },
  USUARIOS: {
    name: 'Usuários',
    url: '/usuarios',
    public: false,
    companyTypes: ['benefit', 'occupational', 'businessUnit'],
    roles: ['admin'],
    requiredModules: null,
  },
  VENDAS: {
    name: 'Vendas',
    url: '/vendas',
    public: false,
    companyTypes: ['optics'],
    roles: ['employee', 'admin'],
    requiredModules: null,
  },
  VENDAS_CADASTRAR: {
    name: 'Nova venda',
    url: '/vendas/nova-venda',
    public: false,
    companyTypes: ['optics'],
    roles: ['employee', 'admin'],
    requiredModules: null,
  },
  VISUALIZAR_UNIDADE_DE_NEGOCIO: {
    name: 'Empresas',
    url: '/empresas/unidade-de-negocio/:id',
    public: true,
    companyTypes: null,
    roles: null,
    requiredModules: null,
    goToUrl: (companyId: string) => `/empresas/unidade-de-negocio/${companyId}`,
  },
};
