import BVUtils from '@bv/utils';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import * as singleSpa from 'single-spa';
import { userViewTypeAtom } from './atom/auth.atom';
import ChatHubSpot from './components/ChatHubSpot';
import { SelectTypeUser } from './components/SelectTypeUser';
import { Toaster } from 'sonner';
import { TNotification, toastHandler } from './utils/toast.util';
import { routePermissionHandler } from './utils/permission.util';
import BVStyleguide from '@bv/styleguide';
import BVUtilsOld from '@bv/utils-old';
import { PUBLIC_ROUTES } from './consts/routes';

const isPublicRoute = (pathname: string) => {
  return PUBLIC_ROUTES.includes(pathname);
};

function logout() {
  window.localStorage.clear();
  BVUtilsOld.cookiesHelper.clearAuthCookies();
  window.location.reload();
}

export default function App(props) {
  const token = BVUtilsOld.cookiesHelper.getAccessToken();
  const [userViewType, setUserViewType] = useAtom(userViewTypeAtom);
  const [showUserViewTypeSelector, setShowUserViewTypeSelector] = useState(false);
  const [loading, setLoading] = useState(true);

  const setupBV = useCallback(() => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const tokenDecoded = BVUtils.token.getDecodedToken();
        let newUserViewType = null;

        if (!userViewType) {
          // Se o usuário não tiver um tipo de usuário definido, ele será redirecionado para a tela de seleção de tipo de usuário ou no caso de ter apenas uma role, será definido automaticamente
          if (tokenDecoded.root) {
            newUserViewType = 'root';
            setUserViewType(newUserViewType);
            window.location.reload();
          } else if (tokenDecoded.roles.length > 1 && !userViewType) {
            setUserViewType(null);
            setShowUserViewTypeSelector(true);
            setLoading(false);
          } else {
            newUserViewType = tokenDecoded.roles[0];
            setUserViewType(newUserViewType);
            window.location.reload();
          }
        } else {
          // Se o usuário já tiver um tipo de usuário definido, tratar aplicacoes que ele pode acessar
          await routePermissionHandler();
        }
        setLoading(false);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }, []);

  const setupEvents = useCallback(() => {
    BVUtils.emitter.on('@bv/middleware<-notify', (data: TNotification) => {
      console.log('Recebeu notificação', data);
      toastHandler(data);
    });
  }, []);

  useEffect(() => {
    setupEvents();
    if (token) {
      // Registra as aplicações que o usuario autenticado pode acessar
      if (isPublicRoute(window.location.pathname) || window.location.pathname === '/') {
        logout();
      }
      setupBV().catch((err) => console.log({ err }));
    } else {
      // Se o usuário não estiver autenticado, checa se a rota acessada é pública e redireciona para login caso não seja
      if (!isPublicRoute(window.location.pathname)) {
        singleSpa.navigateToUrl('/login');
      }
      setLoading(false);
    }
  }, []);

  if (loading) return userViewType === 'patient' ? <BVStyleguide.PageLoaderEyecare /> : <BVStyleguide.PageLoader />;

  if (showUserViewTypeSelector) {
    return (
      <section>
        <SelectTypeUser />
        <Toaster richColors position="top-right" style={{ marginRight: 32 }} />
      </section>
    );
  }

  if (token && userViewType) {
    return (
      <section>
        <ChatHubSpot />
        <Toaster richColors position="top-right" style={{ marginRight: 32 }} />
      </section>
    );
  }

  return (
    <section>
      <Toaster richColors position="top-right" style={{ marginRight: 32 }} />
    </section>
  );
}
