import { toast } from 'sonner';

export type TNotification = {
  message: string;
  type: 'success' | 'error' | 'info' | 'warning';
  title?: string;
};

export type TNotificationPromise = {
  loadingText: string;
  successMessage: string;
  errorMessage?: string;
  promise: Promise<any>;
  successCallback?: () => void;
  errorCallback?: () => void;
  resolve: (reason?: any) => void;
  reject: (reason?: any) => void;
};

export const toastHandler = (notification: TNotification) => {
  const { message, type, title } = notification;
  if (title) {
    toast[type](title, {
      description: message,
    });
  } else {
    toast[type](message);
  }
};

export const toastPromiseHandler = (notification: TNotificationPromise) => {
  const { loadingText, successMessage, errorMessage, promise, resolve, reject, successCallback, errorCallback } = notification;
  toast.promise(promise, {
    loading: loadingText,
    success: (data) => {
      if (successCallback) successCallback();
      resolve(successMessage);
      return successMessage;
    },
    error: () => {
      if (errorCallback) errorCallback();
      reject(errorMessage || 'Erro inesperado.');
      if (errorMessage) return errorMessage;
    },
  });
};
