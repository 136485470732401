import BVUtils from '@bv/utils';
import { useCallback, useEffect, useState } from 'react';
import * as singleSpa from 'single-spa';
import { Toaster } from 'sonner';
import { TNotification, TNotificationPromise, toastHandler, toastPromiseHandler } from './utils/toast.util';
import { routePermissionHandler } from './utils/permission.util';
import BVStyleguide from '@bv/styleguide';
import { PUBLIC_ROUTES } from './consts/routes';
import bvUtils from '@bv/utils';
import Clarity from '@microsoft/clarity';

//trigger pipeline4

const token = bvUtils.token.getDecodedToken();

const initClarity = () => {
  Clarity.init('pug6fxhynx');
  const user = token as { _id: string; name: string; email?: string; individualIdentifier?: string } | null;
  user && Clarity.identify(user._id, user.email || user.individualIdentifier || null, window.location.pathname, user.name); // only custom-id is required
};

const isPublicRoute = (pathname: string) => {
  return PUBLIC_ROUTES.includes(pathname);
};

function logout() {
  window.localStorage.clear();
  bvUtils.cookies.clearAuthCookies();
  window.location.reload();
}

export default function App(props) {
  const userViewType = BVUtils.session.getUserViewTypeUtil();
  const [loading, setLoading] = useState(true);

  const setupBV = useCallback(() => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        // Se o usuário já tiver um tipo de usuário definido, tratar aplicacoes que ele pode acessar
        await routePermissionHandler();

        setLoading(false);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }, []);

  const setupEvents = useCallback(() => {
    BVUtils.emitter.on('@bv/middleware<-notify', (data: TNotification) => {
      console.log('Recebeu notificação', data);
      toastHandler(data);
    });

    BVUtils.emitter.on('@bv/middleware<-notify-promise', (data: TNotificationPromise) => {
      console.log('Recebeu notificação de promise', data);
      toastPromiseHandler(data);
    });
  }, []);

  useEffect(() => {
    setupEvents();
    if (token) {
      if (process.env.NODE_ENV === 'production') initClarity();
      // Registra as aplicações que o usuario autenticado pode acessar
      if (isPublicRoute(window.location.pathname) || window.location.pathname === '/') {
        logout();
      }
      setupBV().catch((err) => console.log({ err }));
    } else {
      // Se o usuário não estiver autenticado, checa se a rota acessada é pública e redireciona para login caso não seja
      if (!isPublicRoute(window.location.pathname)) {
        singleSpa.navigateToUrl('/login');
      }
      setLoading(false);
    }
  }, []);

  if (loading) return userViewType === 'patient' ? <BVStyleguide.PageLoaderEyecare /> : <BVStyleguide.PageLoader />;

  return (
    <section className="fixed bottom-6 left-0 z-[200]">
      <Toaster richColors position="bottom-center" />
    </section>
  );
}
